import React, { useEffect, useState } from 'react'
import classes from './Admin.module.scss';

function Admin() {
    const [token, setToken] = useState(null);
    const [news, setNews] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [newNews, setNewNews] = useState({ url: '', title: '', description: '', date: '', image: '' });
    const [newReview, setNewReview] = useState({ id: '', text: '', author: '', role: '' });

    async function fetchContent(token) {
        const GITHUB_PAT = token; // 🔥 Store in .env, NOT in frontend
        const repoOwner = "tejasavkhattar";
        const repoName = "swaza";
        const filePath = "src/content.json"; // Adjust based on repo structure

        const url = `https://api.github.com/repos/${repoOwner}/${repoName}/contents/${filePath}?ref=revamp`;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: `token ${GITHUB_PAT}`,
                Accept: "application/vnd.github.v3+json"
            }
        });

        if (!response.ok) {
            console.error("Failed to fetch content:", response.statusText);
            return null;
        }

        const data = await response.json();
        const fileContent = atob(data.content); // Decode base64 content
        const finalData = JSON.parse(fileContent);
        console.log(JSON.parse(fileContent)); // Convert to JSON
        setNews(finalData.news);
        setReviews(finalData.review);
    }

    async function updateContent(newContent) {
        const commitMessage = "Update content.json via UI";

        const GITHUB_PAT = token; // 🔥 Store in .env, NOT in frontend
        const repoOwner = "tejasavkhattar";
        const repoName = "swaza";
        const filePath = "src/content.json"; // Adjust based on repo structure


        // Get latest commit SHA (GitHub requires this)
        const getFileUrl = `https://api.github.com/repos/${repoOwner}/${repoName}/contents/${filePath}`;


        // Step 1: Get the latest SHA from the specified branch
        const fileResponse = await fetch(`${getFileUrl}?ref=revamp`, {
            method: "GET",
            headers: {
                Authorization: `token ${GITHUB_PAT}`,
                Accept: "application/vnd.github.v3+json"
            }
        });

        if (!fileResponse.ok) {
            console.error("❌ Failed to fetch latest SHA:", fileResponse.statusText);
            return false;
        }

        const fileData = await fileResponse.json();
        const latestSHA = fileData.sha; // Get latest commit SHA


        // Encode new content
        const updatedContent = btoa(unescape(encodeURIComponent(JSON.stringify(newContent, null, 2))));

        // Commit new content
        const updateResponse = await fetch(getFileUrl, {
            method: "PUT",
            headers: {
                Authorization: `token ${GITHUB_PAT}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                message: commitMessage,
                content: updatedContent,
                sha: latestSHA,
                branch: "revamp",
            })
        });

        if (updateResponse.ok) {
            console.log("✅ JSON updated successfully!");
            return true;
        } else {
            console.error("❌ Failed to update JSON", await updateResponse.text());
            return false;
        }
    }

    const handleInputChange = (event) => {
        setToken(event.target.value);
    };

    const handleNewsChange = (e) => {
        setNewNews({ ...newNews, [e.target.name]: e.target.value });
    };

    const handleReviewChange = (e) => {
        setNewReview({ ...newReview, [e.target.name]: e.target.value });
    };

    const addNews = () => {
        setNews([...news, newNews]);
        setNewNews({ url: '', title: '', description: '', date: '', image: '' });
    };

    const addReview = () => {
        setReviews([...reviews, newReview]);
        setNewReview({ id: '', text: '', author: '', role: '' });
    };

    const deleteNews = (index) => {
        setNews(news.filter((_, i) => i !== index));
    };

    const deleteReview = (index) => {
        setReviews(reviews.filter((_, i) => i !== index));
    };

    return (
        <div className={classes.AdminContainer}>
            <input type="text"
                value={token}
                onChange={handleInputChange}
                placeholder='Enter the token' />
            <button onClick={() => fetchContent(token)}>View Data</button>

            <h2>News</h2>
            <table className={classes.Table}>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>News Link</th>
                        <th>Description</th>
                        <th>Date</th>
                        <th>Image</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {news.map((item, index) => (
                        <tr key={index}>
                            <td>{item.title}</td>
                            <td>{item.url}</td>
                            <td>{item.description}</td>
                            <td>{item.date}</td>
                            <td>{item.image}</td>
                            <td><button onClick={() => deleteNews(index)}>Delete</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <input name="url" value={newNews.url} onChange={handleNewsChange} placeholder="URL" />
            <input name="title" value={newNews.title} onChange={handleNewsChange} placeholder="Title" />
            <input name="description" value={newNews.description} onChange={handleNewsChange} placeholder="Description" />
            <input name="date" value={newNews.date} onChange={handleNewsChange} placeholder="Date" />
            <input name="image" value={newNews.image} onChange={handleNewsChange} placeholder="Image" />
            <button onClick={addNews}>Add News</button>

            <h2>Reviews</h2>
            <table className={classes.Table}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Text</th>
                        <th>Author</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {reviews.map((item, index) => (
                        <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.text}</td>
                            <td>{item.author}</td>
                            <td>{item.role}</td>
                            <td><button onClick={() => deleteReview(index)}>Delete</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <input name="id" value={newReview.id} onChange={handleReviewChange} placeholder="ID" />
            <input name="text" value={newReview.text} onChange={handleReviewChange} placeholder="Text" />
            <input name="author" value={newReview.author} onChange={handleReviewChange} placeholder="Author" />
            <input name="role" value={newReview.role} onChange={handleReviewChange} placeholder="Role" />
            <button onClick={addReview}>Add Review</button>
            <button onClick={() => updateContent({ news, review: reviews })}>Save Data</button>
        </div>
    )
}

export default Admin