import React, { useState } from 'react'
import classes from "./NavBar.module.scss";
import Logo from "../../assets/logo.svg";
import Hamburger from "../../assets/hamburger.svg";
import { Link, useLocation } from 'react-router-dom';

const menuOptions = [
  { key: '1', name: 'Home', route: '/' },
  { key: '2', name: 'Our Technology', route: '/our-technology', id: "our-technology" },
  // { key: '3', name: 'About Us', route: '/about-us' },
  // { key: '4', name: 'Pipeline & Roadmap', route: '/roadmap' },
  { key: '5', name: 'News', route: '/news', id: "news" },
];

function NavBar() {
  const [hamburgerOptionsVisible, setHamburgerOptionsVisible] = useState(false);

  const location = useLocation();
  // Split the pathname into parts and filter out any empty strings (in case of a trailing slash)
  const segments = location.pathname.split('/').filter(Boolean);
  // Get the last segment from the array
  const lastSegment = segments[segments.length - 1];

  return (
    <div className={classes.NavBar} style={hamburgerOptionsVisible ? { background: "#022145" } : {}}>
      <Link to='/'><img src={Logo} className={classes.SwazaLogo} alt="swaza-logo" /></Link>
      <img src={Hamburger} onClick={() => { setHamburgerOptionsVisible(!hamburgerOptionsVisible) }} className={classes.Hamburger} alt="swaza-logo" />
      <div className={classes.NavBarOptions}>
        {menuOptions.map((option) => (
          <Link to={option.route}>
            <div key={option.key} className={!(lastSegment === option.id) ? classes.NavBarOptionText : classes.NavBarOptionTextSelected}>
              {option.name}
            </div>
          </Link>
        ))}

      </div>
      {hamburgerOptionsVisible && <div className={classes.HamburgerOptions}>
        {menuOptions.map((option) => (
          <Link to={option.route}>
            <div key={option.key} className={classes.HamburgerOptionText}>
              {option.name}
            </div>
          </Link>
        ))}
      </div>}
    </div>
  )
}

export default NavBar