import React from 'react';
import { Route, Routes } from 'react-router-dom';

import App from './App';
import Roadmap from './pages/Roadmap/Roadmap';
import AboutUs from './pages/AboutUs/AboutUs';
import News from './pages/News/News';
import Technology from './pages/Technology/Technology';
import Admin from './pages/Admin/Admin';

const AppRoute = () => {


  return (
      <Routes>
            <Route path='/' exact element={<App/>} />
            {/* <Route path='/roadmap' exact element={<Roadmap/>} /> */}
            <Route path='/about-us' exact element={<AboutUs/>} />
            <Route path='/news' exact element={<News/>} />
            <Route path='/our-technology' exact element={<Technology/>} />
            <Route path='/admin' exact element={<Admin/>} />
      </Routes>
  );
};

export default AppRoute;
