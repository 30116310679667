import React, { useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import NewsPhoto from "../../assets/newsPhoto.png";
import classes from "./News.module.scss";
import Footer from "../../components/Footer/Footer";

import TopLeft from '../../assets/top-left.svg';
import TopRight from '../../assets/top-right.svg';
import BottomLeft from '../../assets/bottom-left.svg';
import BottomRight from '../../assets/bottom-right.svg';

import ReadMore from '../../assets/ReadMore.png';
import Wave from "../../assets/wave_animation.gif";
import data from "../../content.json";

const ARTICLES_PER_PAGE = 6;


function News() {
    const { news } = data;
    const [page, setPage] = useState(0);
    return (
        <div className={classes.MainContainer}>
            <NavBar />
            <img src={Wave} alt="gradient" className={classes.WaveGradient} />
            <img src={TopLeft} alt="gradient" className={classes.MeshGradient} />
            <img src={BottomLeft} alt="gradient" className={classes.MeshGradient1} />
            <img src={TopRight} alt="gradient" className={classes.MeshGradient2} />
            <img src={BottomRight} alt="gradient" className={classes.MeshGradient3} />
            <div className={classes.Header}><span className={classes.Blue}>News</span></div>
            <div className={classes.CardContainer}>
                {news.slice(page * ARTICLES_PER_PAGE, page * ARTICLES_PER_PAGE + ARTICLES_PER_PAGE).map((item) => (
                    <a href={item.url} target="_blank" rel="noreferrer" className={classes.CardFooterUrl} >
                        <div className={classes.Card}>
                            <img src={item.image} className={classes.CardImage} alt={item.title} />
                            <div className={classes.CardContent}>
                                <div className={classes.CardHeader}>{item.title}</div>
                                <div className={classes.CardDate}>{item.date}</div>
                                <div className={classes.CardDescription}>{item.description}</div>
                                <div className={classes.CardFooter}>
                                    Read more
                                    <img src={ReadMore} alt="gradient" className={classes.ReadMoreButton} />
                                </div>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
            {news.length > 6 ? <div className={classes.PageControl}>
                <button className={`${classes.NextButton} ${page * ARTICLES_PER_PAGE + ARTICLES_PER_PAGE <= news.length ? "" : classes.NextButtonDisabled}`} onClick={() => {
                    if (page * ARTICLES_PER_PAGE + ARTICLES_PER_PAGE <= news.length) setPage(page + 1)
                }}>Next &gt;</button>
                <button className={`${classes.NextButton} ${page === 0 ? classes.NextButtonDisabled : ""}`} onClick={() => {
                    if (page > 0) setPage(page - 1)
                }}>&lt; Back</button>
            </div> : null}
            <Footer />
        </div>
    );
}

export default News;
