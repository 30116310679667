import React, { useRef, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import classes from "./Technology.module.scss";
import Footer from "../../components/Footer/Footer";
import Wave from "../../assets/wave_animation.gif";
import Icon2 from '../../assets/icon2.gif';
import Icon1 from '../../assets/icon1.gif';
import Icon4 from '../../assets/icon4.gif';
import Icon5 from '../../assets/icon5.gif';
import Icon6 from '../../assets/icon6.gif';
import Icon7 from '../../assets/icon7.gif';
import Product1 from '../../assets/Product1.png';
import Product2 from '../../assets/Product2.png';
import Product3 from '../../assets/Product3.png';
import Product4 from '../../assets/Product4.png';
import Product5 from '../../assets/Product5.png';
import TopLeft from '../../assets/top-left.svg';
import TopRight from '../../assets/top-right.svg';
import BottomLeft from '../../assets/bottom-left.svg';
import BottomRight from '../../assets/bottom-right.svg';

import AngleCircleDown from '../../assets/AngleCircleDown.svg';
import AngleCircleUp from '../../assets/AngleCircleUp.svg';
import LeftChevron from '../../assets/ChevronLeft.svg';
import RightChevron from '../../assets/ChevronRight.svg';
import Slider from "react-slick";


function Technology() {
    const FEATURES = [
        {
            header: "First-of-Its-Kind Oxygen Nanotherapy",
            desc: "SWAZA-1 is patented, breakthrough liquid nanoparticle technology featuring a unique set of physiochemical properties.",
        },
        {
            header: "Unmatched Oxygen and CO₂ Exchange",
            desc: "Engineered for dynamic gas exchange, SWAZA-1 enables efficient oxygen delivery and carbon dioxide removal, even in severely compromised surface levels such as the lung, skin, and colon.",
        },
        {
            header: "First-of-Its-Kind Oxygen Nanotherapy",
            desc: "SWAZA-1 is patented, breakthrough liquid nanoparticle technology featuring a unique set of physiochemical properties.",
        },
        {
            header: "Designed for Fibrotic and Inflamed Lungs",
            desc: "Its liquid nanoparticles penetrate fluid and fibrotic barriers, even  dense scar tissue and collagen-rich fluids, ensuring gas exchange even in conditions like IPF, COPD, and acute respiratory distress.",
        },
        {
            header: "A Game-Changer in Respiratory Medicine",
            desc: "First of its kind topical breathing aid capable of improving lung function without the use of oxygen tanks or ventilators.",
        },
        {
            header: "High Oxygen and Carbon Dioxide Absorption Capacity",
            desc: "Absorbs large amounts of oxygen from ambient air and delivers a dramatically higher oxygen payload compared to natural lung fluid, ensuring critical oxygenation where traditional methods fail.",
        },
        {
            header: "Non-Invasive, Life-Saving Alternative",
            desc: "Unlike mechanical ventilation, SWAZA-1 offers a portable, non-toxic solution that provides oxygen support without intubation.",
        },
    ];

    const APPLICATION = [
        {
            gif: Icon1,
            text: "Chronic obstructive pulmonary disease (COPD)"
        },
        {
            gif: Icon2,
            text: "Respiratory Infections"
        },
        {
            gif: Icon4,
            text: "Neuromuscular diseases impacting lung and airway function"
        },
        {
            gif: Icon6,
            text: "Interstitial and Restrictive lung diseases"
        },
        {
            gif: Icon5,
            text: "Asthma and allergic reactions"
        },
        {
            gif: Icon7,
            text: "Suffocation, drowning, or smoke exposure"
        },

    ];

    const PRODUCT = [
        {
            image: Product1,
            desc: "Breathing Aid",
            features: ["First of its kind - draws oxygen from the air.", "Actively shuttles gases through fluid and fibrotic barriers at the lung surface"]
        },
        {
            image: Product5,
            desc: "Wound and Burn Hydrogels",
            features: ["Advanced biomaterial for burns and ulcers."]
        },
        {
            image: Product3,
            desc: "Thermoreversible Enema",
            features: ["Emergency oxygenation for severe trauma."]
        },
        {
            image: Product4,
            desc: "Drug Delivery Solutions",
            features: ["Enables delivery of molecules to the pulmonary system and across fibrotic barriers."]
        },
        {
            image: Product2,
            desc: "Regenerative Topicals",
            features: ["Regenerative materials for medical aesthetic applications."]
        },
    ];

    const [activeIndex, setActiveIndex] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);


    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const sliderRef = useRef();

    const nextReview = () => {
        sliderRef.current.slickNext();
    };

    const prevReview = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        useTransform: false,
        arrows: false,
        className: classes.MobileProduct,
        slidesToShow: 1,
        infinite: false,
        slidesToScroll: 1,
        centerMode: true,
        beforeChange: (current, next) => setCurrentIndex(next),
    };

    return (
        <div className={classes.MainContainer}>
            <NavBar />
            <img src={Wave} alt="gradient" className={classes.WaveGradient} />
            <img src={TopLeft} alt="gradient" className={classes.MeshGradient} />
            <img src={BottomLeft} alt="gradient" className={classes.MeshGradient1} />
            <img src={TopRight} alt="gradient" className={classes.MeshGradient2} />
            <img src={BottomRight} alt="gradient" className={classes.MeshGradient3} />

            <div className={classes.HeaderContainer}>
                <div className={classes.Header}>Behind Our <span className={classes.Blue}>Technology</span></div>
                <div className={classes.SubHeader}>Swaza nanoparticles provide a groundbreaking respiratory solution by continuously releasing oxygen and absorbing CO₂ on a cellular level. This self-buffering system adapts to the lungs' dynamic needs, offering hope for chronic respiratory patients and future therapies.</div>
            </div>

            <div className={classes.FeatureContainer}>
                {FEATURES.map((feature) => (
                    <div className={classes.EachFeatureContainer}>
                        <div className={classes.FeatureHeader}>{feature.header}</div>
                        <div className={classes.FeatureSubHeader}>{feature.desc}</div>
                    </div>
                ))}
            </div>

            <div className={classes.AccordionContainer}>
                {FEATURES.map((feature, index) => (
                    <div key={index} className={classes.AccordionItem}>
                        <div className={classes.AccordionHeader} onClick={() => toggleAccordion(index)}>
                            <span>{feature.header}</span>
                            {activeIndex === index ? <img className={classes.AccordionIcon} src={AngleCircleUp} alt="" /> : <img className={classes.AccordionIcon} src={AngleCircleDown} alt="" />}
                        </div>
                        <div
                            className={`${classes.AccordionContent} ${activeIndex === index ? classes.Active : ''}`}
                        >
                            {feature.desc}
                        </div>
                    </div>
                ))}
            </div>

            <div className={classes.ApplicationContainer}>
                <div className={classes.Header}>Swaza has a wide range of <span className={classes.Blue}>therapeutic applications</span> for <span className={classes.Blue}>breathing</span></div>

                <div className={classes.Applications}>
                    {APPLICATION.map((feature) => (
                        <div className={classes.EachApplicationContainer}>
                            <img src={feature.gif} alt="" className={classes.ApplicationImage} />
                            <div className={classes.ApplicationHeader}>{feature.text}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className={classes.ProductContainer}>
                <div className={classes.Header}><span className={classes.Blue}>Product</span> Pipeline</div>

                <div className={classes.Products}>
                    {PRODUCT.map((feature) => (
                        <div className={classes.EachProductContainer}>
                            <img src={feature.image} alt="" className={classes.ProductImage} />
                            <div className={classes.ProductHeader}>{feature.desc}</div>
                            <ul className={classes.ProductFeatures}>
                                {feature.features.map(f => (
                                    <li>{f}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>


                <div className={classes.MobileProducts}>
                    <Slider {...settings} ref={sliderRef}>
                        {PRODUCT.map((feature) => (
                            <div className={classes.EachMobileProductContainer}>
                                <img src={feature.image} alt="" className={classes.MobileProductImage} />
                                <div className={classes.MobileProductHeader}>{feature.desc}</div>
                                <ul className={classes.ProductFeatures}>
                                    {feature.features.map(f => (
                                        <li>{f}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </Slider>
                    <div className={classes.Navigation}>
                        <img src={LeftChevron} alt="left" onClick={prevReview} className={classes.ArrowButton} style={currentIndex === 0 ? { visibility: "hidden" } : {}} />
                        <img src={RightChevron} alt="right" onClick={nextReview} className={classes.ArrowButton} style={currentIndex === PRODUCT.length -1 ? { visibility: "hidden" } : {}}/>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    );
}

export default Technology;
