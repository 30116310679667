import React from "react";
import classes from "./Footer.module.scss";
import Logo from "../../assets/logo.svg";
import Facebook from "../../assets/facebook.png";
import LinkedIn from "../../assets/LinkedIn.svg";
import Twitter from "../../assets/twitter.png";
import Instagram from "../../assets/instagram.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={classes.MainContainer}>
      <hr className={classes.StepsContainerBorder} />
      <div className={classes.Info}>
        <img className={classes.Logo} src={Logo} alt="logo" />
        <div className={classes.Navigation}>
          <Link to="/"><div>Home</div></Link>
          <Link to="/our-technology"><div>Our Technology</div></Link>
          <Link to="/news"><div>News</div></Link>
        </div>

        <div className={classes.SocialLogoContainer}>
          {/* <img className={classes.SocialLogo} src={Facebook} alt="facebook" /> */}
          {/* <img className={classes.SocialLogo} src={Instagram} alt="instagram" /> */}
          <a href="https://www.linkedin.com/company/swaza" target="_blank" rel="noreferrer">
            <img className={classes.SocialLogo} src={LinkedIn} alt="linkedin" />
          </a>
          {/* <img className={classes.SocialLogo} src={Twitter} alt="twitter" /> */}


        </div>
      </div>
      <div className={classes.Contact}>
        <div className={classes.Copyright}>© 2022 Swaza Inc. All Rights reserved</div>
      </div>
    </div>
  );
};

export default Footer;
