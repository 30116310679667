import React, { useRef, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import showVideo from "../../assets/4k-dust-particles.mp4";
import classes from "./index.module.scss";
import Footer from "../../components/Footer/Footer";
import Layer1 from '../../assets/image.png';
import Layer2 from '../../assets/Layer2.png';

import r42Logo from "../../assets/r42-logo.png";
import airStream from "../../assets/airstream-logo.png";
import apex from "../../assets/apex-logo.png";
import johnson from "../../assets/johnson-logo.png";
import nato from "../../assets/nato-logo.png";
import diana from "../../assets/diana-logo.png";
import barda from "../../assets/barda-logo.png";

import LeftChevron from "../../assets/LeftChevron.svg";
import RightChevron from "../../assets/RightChevron.svg";


import jay from "../../assets/jay_new.png";
import art from "../../assets/artem_new.png";
import niki from "../../assets/niki_new.png";
import retzios from "../../assets/ana_new.png";

import naveen from "../../assets/naveen_new.png";
import park from "../../assets/park_new.png";
import snyder from "../../assets/snyder_new.png";
import kyle from "../../assets/kyle_new.png";
import paul from "../../assets/paul_new.png";
import robert from "../../assets/lum_new.png";
import SwazaLungs from "../../assets/SWAZA_Lungs.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import data from "../../content.json";
import QuoteTop from "../../assets/QuoteTop.svg";
import QuoteBottom from "../../assets/QuoteBottom.svg";


function Home() {

  const logos = [
    {
      logo: r42Logo,
      style: window.innerWidth >= 600 ? { height: "3.7vw", width: "10vw" } : { height: "7.6vw", width: "20vw" }
    },
    {
      logo: airStream,
      style: window.innerWidth >= 600 ? { height: "4vw", width: "13.8vw" } : { height: "12vw", width: "36vw" }
    },
    {
      logo: apex,
      style: window.innerWidth >= 600 ? { height: "5vw", width: "6vw" } : { height: "16vw", width: "18vw" }
    },
    {
      logo: johnson,
      style: window.innerWidth >= 600 ? { height: "5vw", width: "18vw" } : { height: "10vw", width: "40vw" }
    },
    {
      logo: nato,
      style: window.innerWidth >= 600 ? { height: "7.8vw", width: "5vw" } : { height: "20vw", width: "12vw" }
    },
    {
      logo: diana,
      style: window.innerWidth >= 600 ? { height: "3.7vw", width: "11.7vw" } : { height: "8vw", width: "27vw" }
    },
    {
      logo: barda,
      style: window.innerWidth >= 600 ? { height: "8vw", width: "8vw" } : { height: "20vw", width: "19vw" }
    },
  ];

  const team = [
    {
      image: jay,
      name: "Prof. Jayakumar Rajadas, PhD",
      role: "Co-Founder & Chief Scientist",
    },
    {
      image: niki,
      name: "Niki V. Santo",
      role: "Co-Founder & CEO",
    },
    {
      image: art,
      name: "Dr. Artem Trotsyuk, PhD",
      role: "CTO",
    },
    {
      image: retzios,
      name: "Dr. Anastassios Retzios, PhD",
      role: "dir. of Regulatory Clinical Affairs",
    },
    {
      image: robert,
      name: "Dr. Robert Lum, PhD",
      role: "Chemistry Manufacturing and Controls",
    },
    {
      image: park,
      name: "Douglas Park, JD PhD",
      role: "General Counsel",
    },
    {
      image: snyder,
      name: "Prof. Michael Snyder, PhD",
      role: "Chair & W. Ascherman Prof.",
    },
    {
      image: kyle,
      name: "Kyle Brewer, PhD",
      role: "Senior Scientist",
    },
    {
      image: paul,
      name: "Dr. Paul Weiss, PhD",
      role: "Dist. Prof. of Bioengineering",
    },
    {
      image: naveen,
      name: "Dr. Naveen Jayakumar, MD",
      role: "Pulmonology and Critical Care",
    },
  ];

  const { review } = data;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [contact, setContact] = useState(false);
  const sliderRef = useRef();

  const nextReview = () => {
    sliderRef.current.slickNext();
  };

  const prevReview = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    useTransform: false,
    arrows: false,
    // infinite: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    beforeChange: (current, next) => setCurrentIndex(next),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          infinite: false,
          slidesToScroll: 1,
        }
      },
    ]
  };

  return (
    <>
      <NavBar />
      <div className={classes.HeaderContainer}>
        <video
          src={showVideo}
          type="video/mp4"
          playsInline
          autoPlay
          muted
          loop="loop"
          className={classes.Video}
        />
        <img src={Layer1} alt="layer1" className={classes.Layer1} />
        <img src={Layer2} alt="layer1" className={classes.Layer2} />
        <img src={SwazaLungs} alt="layer1" className={classes.Lungs} />
        <div className={classes.Tagline}>
          <div className={classes.TaglineText}>Harnessing the Power of <span className={classes.WhiteText}>Oxygen</span> through Nanotherapeutics</div>
          <div className={classes.TaglineBody}>Introducing the first non-invasive nanofluid breathing aid for respiratory distress and advanced regenerative biomaterials for superior topical oxygen delivery – all powered by our nanotechnology platform</div>
          <a href="https://drive.google.com/uc?export=download&id=1cMAkULZIbCKTQlKImhvM4QfK_fMLVyXi" rel="noreferrer" download="Swaza_Executive_Summary" target='_blank'><div className={classes.TaglineButton}>Download Summary</div></a>
        </div>
      </div>

      <div className={classes.OurMission}>
        <div className={classes.MissionContent}>
          <div className={classes.MissionHeader}>Redefining <span className={classes.Blue}>Respiratory</span> Care</div>
          <div className={classes.MissionBody}>
            Swaza is pioneering a first-of-its-kind oxygen nanotherapy, enabling O₂ delivery and CO₂ removal through fibrotic lung tissue — without invasive ventilation. Our patented nanofluid technology is the only solution capable of dynamic gas exchange in compromised lungs, addressing a critical gap in respiratory care. With a $56B U.S. market opportunity, Swaza is poised to revolutionize treatment for millions suffering from hypoxemia, COPD, IPF, and other respiratory conditions.
          </div>
        </div>
        <div className={classes.VideoSection}>
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/HX4s8PlnDpw?si=YpFxCWixVjuYjxjR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>

      <div className={classes.CarouselContainer}>
        <div className={classes.MissionHeader}>Setting a New <span className={classes.Blue}>Standard</span></div>

        <Slider {...settings} ref={sliderRef}>
          {review.map(({ text, author, role }, index) => {
            const cardClass = currentIndex === index ? `${classes.MagnifiedCard} ${classes.Card}` : classes.Card;
            return (
              <div key={review.id} className={cardClass}>
                <img className={classes.QuoteTop} src={QuoteTop} alt="quotes" />
                <div className={classes.Review}>{text}</div>
                <div className={classes.ReviewAuthor}>{author}</div>
                <div className={classes.ReviewRole}>{role}</div>
                <img className={classes.QuoteBottom} src={QuoteBottom} alt="quotes" />
              </div>
            );
          })}
        </Slider>
        <div className={classes.Navigation}>
          <img src={LeftChevron} alt="left" onClick={prevReview} className={classes.ArrowButton} style={currentIndex === 0 ? { visibility: "hidden" } : {}} />
          <img src={RightChevron} alt="right" onClick={nextReview} className={classes.ArrowButton} style={currentIndex === review.length - 1 ? { visibility: "hidden" } : {}} />
        </div>
      </div>

      <div className={classes.OurTeamContainer}>
        <div className={classes.Header}>Our <span className={classes.Blue}>Team</span></div>
        <div className={classes.TeamImageContainer}>
          {team.map(({ image, name, role }) => (
            <div className={classes.PersonalContainer}>
              <img src={image} alt="" className={classes.PersonalImage} />
              <div className={classes.PersonalName}>{name}</div>
              <div className={classes.PersonalRole}>{role}</div>
            </div>
          ))}
        </div>
      </div>

      <div div className={classes.PartnersAndInvestors} >
        <div className={classes.Header}>Our <span className={classes.Blue}>Partners</span> and <span className={classes.Blue}>Investors</span></div>
        <div className={classes.LogoContainer}>
          {logos.map(({ logo, style }) => (
            <img src={logo} alt="" style={style} className={classes.Logo} />
          ))}
        </div>
      </div >

      <div className={classes.ContactUsContainer}>
        <video
          src={showVideo}
          type="video/mp4"
          playsInline
          autoPlay
          muted
          loop="loop"
          className={classes.Video}
        />
        <img src={Layer1} alt="layer1" className={classes.Layer1} />
        {/* <img src={Layer2} alt="layer1" className={classes.Layer2} /> */}
        <div className={!contact ? classes.Tagline : classes.DisplayNone}>
          <div className={classes.TaglineText}>Pioneer the Future <span className={classes.WhiteText}>of Medicine with us</span></div>
          <div className={classes.TaglineButton} onClick={() => setContact(true)}>Contact Us</div>
        </div>
        <div className={contact ? classes.TaglineAfterClick : classes.DisplayNone}>
          <form className={classes.ContactUsForm}>
            <div className={classes.TaglineText}>Pioneer the Future <span className={classes.WhiteText}>of Medicine with us</span></div>
            <div className={classes.InputContainer}>
              <input type="text" placeholder="Name" className={classes.InputField} />
              <input type="email" placeholder="Email" className={classes.InputField} />
            </div>
            <textarea placeholder="Message" className={classes.TextArea}></textarea>
            <div className={classes.ContactUsRole}>
              <div className={classes.ContactUsText}>I am interested in knowing more as an</div>
              <div className={classes.RoleContainer}>
                <input type="radio" className={classes.ContactUsRadio} id="html" name="fav_language" value="investor" />
                <label for="investor">Investor</label>
                <input type="radio" style={{ marginLeft: "40px" }} className={classes.ContactUsRadio} id="html" name="fav_language" value="press" />
                <label for="press">Press</label>
              </div>
            </div>
            <div type="submit" className={classes.TaglineButton}>Submit</div>
          </form>
        </div>
      </div>

      <div className={classes.BottomContainer}></div>

      <Footer />
    </>
  );
}

export default Home;
